/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./footer.component";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Code licensed under an "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["href", "https://www.apache.org/licenses/LICENSE-2.0"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Apache-2.0 License"])), (_l()(), i0.ɵted(-1, null, [". Documentation licensed under "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["href", "http://creativecommons.org/licenses/by/4.0/"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["CC BY 4.0"])), (_l()(), i0.ɵted(-1, null, [".\n"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["Version ", "."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.versionInfo == null) ? null : _co.versionInfo.full); _ck(_v, 9, 0, currVal_0); }); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 49152, null, 0, i1.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i0.ɵccf("aio-footer", i1.FooterComponent, View_FooterComponent_Host_0, { nodes: "nodes", versionInfo: "versionInfo" }, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
