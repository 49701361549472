import { InjectionToken } from '@angular/core';
const ɵ0 = () => import("./announcement-bar/announcement-bar.module.ngfactory").then(m => m.AnnouncementBarModuleNgFactory), ɵ1 = () => import("./api/api-list.module.ngfactory").then(m => m.ApiListModuleNgFactory), ɵ2 = () => import("./contributor/contributor-list.module.ngfactory").then(m => m.ContributorListModuleNgFactory), ɵ3 = () => import("./search/file-not-found-search.module.ngfactory").then(m => m.FileNotFoundSearchModuleNgFactory), ɵ4 = () => import("./resource/resource-list.module.ngfactory").then(m => m.ResourceListModuleNgFactory), ɵ5 = () => import("./toc/toc.module.ngfactory").then(m => m.TocModuleNgFactory), ɵ6 = () => import("./code/code-example.module.ngfactory").then(m => m.CodeExampleModuleNgFactory), ɵ7 = () => import("./code/code-tabs.module.ngfactory").then(m => m.CodeTabsModuleNgFactory), ɵ8 = () => import("./current-location/current-location.module.ngfactory").then(m => m.CurrentLocationModuleNgFactory), ɵ9 = () => import("./expandable-section/expandable-section.module.ngfactory").then(m => m.ExpandableSectionModuleNgFactory), ɵ10 = () => import("./live-example/live-example.module.ngfactory").then(m => m.LiveExampleModuleNgFactory), ɵ11 = () => import("./operator-decision-tree/operator-decision-tree.module.ngfactory").then(m => m.OperatorDecisionTreeModuleNgFactory);
// Modules containing custom elements must be set up as lazy-loaded routes (loadChildren)
// TODO(andrewjs): This is a hack, Angular should have first-class support for preparing a module
// that contains custom elements.
export const ELEMENT_MODULE_LOAD_CALLBACKS_AS_ROUTES = [
    {
        selector: 'aio-announcement-bar',
        loadChildren: ɵ0
    },
    {
        selector: 'aio-api-list',
        loadChildren: ɵ1
    },
    {
        selector: 'aio-contributor-list',
        loadChildren: ɵ2
    },
    {
        selector: 'aio-file-not-found-search',
        loadChildren: ɵ3
    },
    {
        selector: 'aio-resource-list',
        loadChildren: ɵ4
    },
    {
        selector: 'aio-toc',
        loadChildren: ɵ5
    },
    {
        selector: 'code-example',
        loadChildren: ɵ6
    },
    {
        selector: 'code-tabs',
        loadChildren: ɵ7
    },
    {
        selector: 'current-location',
        loadChildren: ɵ8
    },
    {
        selector: 'expandable-section',
        loadChildren: ɵ9
    },
    {
        selector: 'live-example',
        loadChildren: ɵ10
    },
    {
        selector: 'aio-operator-decision-tree',
        loadChildren: ɵ11
    }
];
/** Injection token to provide the element path modules. */
// export const ELEMENT_MODULE_PATHS_TOKEN = new InjectionToken('aio/elements-map');
/** Map of possible custom element selectors to their lazy-loadable module paths. */
// export const ELEMENT_MODULE_PATHS = new Map<string, () => Promise<any>>();
// ELEMENT_MODULE_PATHS_AS_ROUTES.forEach(route => {
//   ELEMENT_MODULE_PATHS.set(route.selector, route.loadChildren);
// });
/** Injection token to provide the element path modules. */
export const ELEMENT_MODULE_LOAD_CALLBACKS_TOKEN = new InjectionToken('aio/elements-map');
/** Map of possible custom element selectors to their lazy-loadable module paths. */
export const ELEMENT_MODULE_LOAD_CALLBACKS = new Map();
ELEMENT_MODULE_LOAD_CALLBACKS_AS_ROUTES.forEach(route => {
    ELEMENT_MODULE_LOAD_CALLBACKS.set(route.selector, route.loadChildren);
});
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
