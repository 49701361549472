<div id="top-of-page"></div>

<div *ngIf="isFetching" class="progress-bar-container">
  <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
</div>

<mat-toolbar
  color="primary"
  class="app-toolbar no-print"
  [class.transitioning]="isTransitioning"
>
  <mat-toolbar-row class="notification-container">
    <aio-notification
      notificationId="angular-v6-announcement"
      expirationDate="2018-07-01"
      [dismissOnContentClick]="true"
      (dismissed)="notificationDismissed()"
    >
      <a
        href="https://blog.angular.io/version-6-0-0-of-angular-now-available-cc56b0efa7a4"
      >
        <mat-icon
          class="icon"
          svgIcon="insert_comment"
          aria-label="Announcement"
        ></mat-icon>
        <span class="message">Version 6 of Angular Now Available!</span>
        <span class="action-button">Learn More</span>
      </a>
    </aio-notification>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <button
      mat-button
      class="hamburger"
      [class.starting]="isStarting"
      (click)="sidenav.toggle()"
      title="Docs menu"
    >
      <mat-icon svgIcon="menu"></mat-icon>
    </button>
    <a class="nav-link home" href="/" [ngSwitch]="isSideBySide">
      <!--<img *ngSwitchCase="true" src="assets/images/logos/angular/logo-nav@2x.png" width="150" height="40" title="Home" alt="Home">-->
      <img
        *ngSwitchCase="true"
        src="assets/images/logos/logo.png"
        width="150"
        height="40"
        title="Home"
        alt="Home"
      />
      <img
        *ngSwitchDefault
        src="assets/images/logos/Rx_Logo_S.png"
        width="37"
        height="40"
        title="Home"
        alt="Home"
      />
    </a>
    <aio-top-menu *ngIf="isSideBySide" [nodes]="topMenuNodes"></aio-top-menu>
    <aio-search-box
      class="search-container"
      #searchBox
      (onSearch)="doSearch($event)"
      (onFocus)="doSearch($event)"
    ></aio-search-box>
    <div class="toolbar-external-icons-container">
      <a
        href="https://github.com/ReactiveX/rxjs"
        title="GitHub"
        target="_blank"
      >
        <img src="assets/images/logos/github-icon.svg" alt="View on GitHub"
      /></a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<aio-search-results
  #searchResultsView
  *ngIf="showSearchResults"
  [searchResults]="searchResults | async"
  (resultSelected)="hideSearchResults()"
></aio-search-results>

<mat-sidenav-container
  class="sidenav-container"
  [class.starting]="isStarting"
  [class.has-floating-toc]="hasFloatingToc"
>
  <mat-sidenav
    [ngClass]="{ collapsed: !isSideBySide }"
    #sidenav
    class="sidenav"
    [mode]="mode"
    [opened]="isOpened"
    (openedChange)="updateHostClasses()"
  >
    <aio-nav-menu
      *ngIf="!isSideBySide"
      [nodes]="topMenuNarrowNodes"
      [currentNode]="currentNodes?.TopBarNarrow"
      [isWide]="false"
    ></aio-nav-menu>
    <aio-nav-menu
      [nodes]="sideNavNodes"
      [currentNode]="currentNodes?.SideNav"
      [isWide]="isSideBySide"
    ></aio-nav-menu>

    <div class="doc-version">
      <aio-select
        (change)="onDocVersionChange($event.index)"
        [options]="docVersions"
        [selected]="currentDocVersion"
      ></aio-select>
    </div>
  </mat-sidenav>

  <section class="sidenav-content" [id]="pageId" role="main">
    <aio-mode-banner
      [mode]="deployment.mode"
      [version]="versionInfo"
    ></aio-mode-banner>
    <aio-doc-viewer
      [class.no-animations]="isStarting"
      [doc]="currentDocument"
      (docReady)="onDocReady()"
      (docRemoved)="onDocRemoved()"
      (docInserted)="onDocInserted()"
      (docRendered)="onDocRendered()"
    >
    </aio-doc-viewer>
    <aio-dt [on]="dtOn" [(doc)]="currentDocument"></aio-dt>
  </section>
</mat-sidenav-container>

<div
  *ngIf="hasFloatingToc"
  class="toc-container no-print"
  [style.max-height.px]="tocMaxHeight"
  (mousewheel)="restrainScrolling($event)"
>
  <aio-lazy-ce selector="aio-toc"></aio-lazy-ce>
</div>

<footer class="no-print">
  <aio-footer [nodes]="footerNodes" [versionInfo]="versionInfo"></aio-footer>
</footer>
